import { Swipe, SwipeItem, Tabs, Tab, Sticky, Tag, Overlay, Popup, Icon, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import Call from '../../common/Call.vue'
import '../../../../assets/icon/font-icon.js'
import { GolGetDayDate } from '../../../../plugins/common/date.js'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        Login: Login,
        LogVisit: LogVisit,
        Clue: Clue,
        Call: Call,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Sticky.name]: Sticky,
        [Tag.name]: Tag,
        [Overlay.name]: Overlay,
        [Popup.name]: Popup,
        [Icon.name]: Icon,
        [Image.name]: Image
    },
    data () {
        return {
            code: '',
            applyCount: 0,
            operator: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
            loadingShow: false,
            shareShow: false,
            role: 'CLT',
            taskCode: '',
            personCode: '',
            task: {},
            consult: { amount: 0, type: 'DOC', endTime: '' },
            introduce: {},
            job: {},
            requ: {},
            welfare: {},
            place: {},
            signup: {},
            other: {},
            payOrderCode: ''
        }
    },
    mounted () {
        var query = this.$route.query
        this.taskCode = query.taskCode
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.retrieveTask()
            this.$refs.logVisit.createLog()
            this.retrievePaidCount()
        },
        initShare () {
            var title = this.task.promoTitle
            var desc = this.task.promoDesc
            var link = 'https://moc.utopanet.com/recruit/consult?taskCode=' + this.taskCode
            if (this.role === 'SEL') {
                link = link + '&refereeType=SEL'
            }
            if (this.role === 'CLT') {
                link = link + '&refereeType=CLT'
            }
            var logo = 'https://moc.utopanet.com/static/logo.png'
            this.$refs.share.initParam(title, desc, link, logo)
        },
        async retrieveTask () {
            this.loadingShow = true
            var that = this
            var pd = { taskCode: that.taskCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveTask', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.task = res.data
                this.createViewClue()
                var detail = res.data.detail
                this.task.endTime = GolGetDayDate(3)
                detail.forEach(element => {
                    if (element.type === 'ITD') {
                        that.introduce = element
                    } else if (element.type === 'JOB') {
                        that.job = element
                    } else if (element.type === 'REQ') {
                        that.requ = element
                    } else if (element.type === 'WFE') {
                        that.welfare = element
                    } else if (element.type === 'PAE') {
                        that.place = element
                    } else if (element.type === 'SGP') {
                        that.signup = element
                    } else if (element.type === 'OTH') {
                        that.other = element
                    }
                })
                this.initShare()
            }
            this.loadingShow = false
        },
        orderSubmit () {
            var vad = this.validateToken()
            if (vad) {
                var backKey = window.const.global.MEMBER_PERSON_MATCH_BACK
                window.sessionStorage.setItem(backKey, window.location.href)
                this.$router.push({ path: '/mde/member/personMatch', query: { taskCode: this.taskCode, business: 'RCT', profile: 'RECRUIT_NORMAL' } })
            }
        },
        async createConsult () {
            var vad = this.validateToken()
            if (vad) {
                this.loadingShow = true
                var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
                var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
                var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
                var pd = {
                    taskCode: this.taskCode,
                    personCode: this.personCode,
                    operatorCode: operator,
                    sellerCode: seller,
                    refereeCode: referee,
                    amount: this.consult.amount,
                    type: this.consult.type
                }
                const { data: res } = await this.$http.post(this.BMS_URL + '/overall/consult/createConsult', this.$qs.stringify(pd))
                this.loadingShow = false
                if (res.status === '200') {
                    this.createOrderClue(res.data.code)
                    this.$dialog.alert({
                        title: '咨询申请',
                        message: '咨询申请成功,请耐心等待'
                    })
                } else {
                    this.$dialog.alert({
                        title: '申请失败',
                        message: res.msg
                    })
                }
            }
        },
        validateToken () {
            var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
            if (token === null) {
                this.$router.push({ path: '/mde/bind' })
                return false
            } else {
                return true
            }
        },
        async payExpense (amount, type) {
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            var pd = {
                appKey: 'WPP_MOC',
                amount: amount,
                attach: '',
                description: '咨询服务购买',
                body: '咨询服务购买',
                detail: '',
                business: '',
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createWJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.createConsult()
            }
        },
        async createOrder () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, taskCode: this.taskCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/order/validateOrder', this.$qs.stringify(pd))
            if (res.status === '200') {
                if (res.data.exist === 'N') {
                    if (this.applyCount > 0) {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '提交简历将扣除1次申请次数，是否继续?'
                        })
                            .then(() => {
                                this.consumePaid('WORK_APPLY', 1)
                                this.executeCreateOrder()
                            })
                    } else {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '业务申请次数不足，是否立即充值?'
                        })
                            .then(() => {
                                this.buyPaid()
                            })
                    }
                } else {
                    this.$dialog.alert({
                        title: '职位申请',
                        message: '职位已申请，请勿重复提交'
                    })
                }
            }
        },
        async executeCreateOrder () {
            this.loadingShow = true
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
            var refereeType = window.sessionStorage.getItem(this.SESSION_REFEREE_TYPE)
            var site = window.sessionStorage.getItem(this.SESSION_SITE)
            var source = window.sessionStorage.getItem(this.SESSION_SOURCE)
            var pd = {
                taskCode: this.taskCode,
                personCode: this.personCode,
                operatorCode: operator,
                sellerCode: seller,
                refereeCode: referee,
                refereeType: refereeType,
                siteCode: site,
                source: source
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/order/createOrder', this.$qs.stringify(pd))
            this.loadingShow = false
            if (res.status === '200') {
                this.createOrderClue(res.data.code)
                this.$dialog.alert({
                    title: '职位申请',
                    message: '职位申请成功,请耐心等待或到店咨询'
                })
            } else {
                this.$dialog.alert({
                    title: '申请失败',
                    message: res.msg
                })
            }
        },
        retHome () {
            this.$router.push('/mde/main/home/recruit')
        },
        telphone () {
            var phone = this.store.linkPhone
            window.location.href = 'tel:' + phone
        },
        showWechat () {
            this.wechatShow = true
        },
        shareImage () {
            this.$router.push({ path: '/mde/recruit/shareImage', query: { taskCode: this.taskCode } })
        },
        home () {
            this.$router.push({ path: '/mde/recruit/home' })
        },
        async consumePaid (key, count) {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { appKey: 'WPP_MOC', paidKey: key, userCode: userCode, count: count }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/consumePaid', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.retrievePaidCount()
            }
        },
        async retrievePaidCount () {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: userCode, appKey: 'WPP_MOC', paidKeys: 'WORK_APPLY' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/retrieveUserCusPaidList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.applyCount = res.data[0].surplusCount
                var key = window.const.global.MEMBER_PERSON_MATCH_CHOSE
                var personCode = window.sessionStorage.getItem(key)
                if (personCode !== null && personCode !== undefined && personCode !== '') {
                    this.personCode = personCode
                    window.sessionStorage.removeItem(key)
                    this.createOrder()
                }
            }
        },
        buyPaid () {
            this.$router.push({ path: '/work/paid' })
        },
        onlineChat () {
            this.$refs.call.callWinShow('true')
        },
        createViewClue () {
            var param = { taskCode: this.taskCode, url: window.location.href }
            var content = '补岗招聘浏览'
            this.$refs.clue.createClue('RECRUIT_VIEW_RECRUIT', this.taskCode, JSON.stringify(param), content)
        },
        createOrderClue (orderCode) {
            alert(orderCode)
            var param = { taskCode: this.taskCode, personCode: this.personCode, orderCode: orderCode, url: window.location.href }
            var content = '补岗招聘订单'
            this.$refs.clue.createClue('RECRUIT_ORDER_RECRUIT', orderCode, JSON.stringify(param), content)
        },
        back () {
            var backurl = window.sessionStorage.getItem(window.const.global.RECRUIT_CONSULT_INDEX_BACK)
            if (backurl !== undefined && backurl !== null) {
                window.location.href = backurl
            } else {
                this.$router.push({ path: '/mde/recruit/home' })
            }
        }
    }
}
