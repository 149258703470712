<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="职位咨询" :callback="back" type="SMP" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('../../../../assets/images/recruit/consult.jpg')"
          class="img"
      /></van-swipe-item>
    </van-swipe>
    <van-row class="simple">
      <van-col span="24">
        <van-row class="name">
          <van-col span="24">{{ task.name }}</van-col>
        </van-row>
        <van-row class="label">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">岗位标签</van-tag></van-col
          >
          <van-col span="18">
            <span
              class="item bgc-gray"
              v-for="lb in task.label"
              :key="lb.name"
              >{{ lb.name }}</span
            >
          </van-col>
        </van-row>
        <van-row class="salary">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">薪资福利</van-tag></van-col
          >
          <van-col span="18" class="fc-orange"
            >{{ task.salaryMin }}-{{ task.salaryMax
            }}<span v-if="task.salaryType === 'YER'">元/年</span
            ><span v-if="task.salaryType === 'MOT'">元/月</span
            ><span v-if="task.salaryType === 'DAY'">元/日</span></van-col
          >
        </van-row>
        <van-row class="amount">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">招聘人数</van-tag></van-col
          >
          <van-col span="18">{{ task.amount }}人</van-col>
        </van-row>
        <van-row class="time">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">截止日期</van-tag></van-col
          >
          <van-col span="18">{{ task.endTime }}（名额有限，招满即止）</van-col>
        </van-row>
        <div class="hr"></div>
        <van-row class="service">
          <van-col span="16" class="price"
            >剩&nbsp;&nbsp;&nbsp;余：<span class="number color-s2"
              >{{ applyCount }}次</span
            ><span class="color-s2">(消耗1次提交简历)</span></van-col
          >
          <van-col span="8" class="button">
            <van-button
              class="btn"
              icon="gold-coin-o"
              :color="COLOR_M"
              size="mini"
              @click="orderSubmit()"
              >提交简历</van-button
            >
          </van-col>
        </van-row>
        <van-row class="service">
          <van-col span="16" class="price"
            ><span class="color-s2">在线咨询，方便快捷</span></van-col
          >
          <van-col span="8" class="button">
            <van-button
              class="btn"
              icon="sign"
              :color="COLOR_S1"
              size="mini"
              @click="onlineChat()"
              >立即咨询</van-button
            >
          </van-col>
        </van-row>
        <van-row class="tips color-s2">
          <van-col span="4">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-notice"></use></svg
          ></van-col>
          <van-col span="20" class="text"
            >推荐人员入职成功，可获取高额奖励</van-col
          >
        </van-row>
      </van-col>
    </van-row>
    <van-row @click="home()">
      <van-col span="24">
        <van-image
          class="focus-img"
          :src="require('../../../../assets/images/recruit/wp-focus.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="detail">
      <van-tabs :active="activeName" :color="COLOR_M" :border="true">
        <van-tab
          title="详情介绍"
          name="introduce"
          v-if="JSON.stringify(introduce) !== '{}'"
        >
          <div
            class="pre-text ta-left content"
            v-html="introduce.content"
          ></div>
        </van-tab>
        <van-tab
          title="薪资福利"
          name="welfare"
          v-if="JSON.stringify(welfare) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ welfare.content }}</div>
        </van-tab>
        <van-tab
          title="工作内容"
          name="job"
          v-if="JSON.stringify(job) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ job.content }}</div>
        </van-tab>
        <van-tab
          title="报名方式"
          name="signup"
          v-if="JSON.stringify(signup) !== '{}'"
        >
          <div class="pre-text ta-left content" v-if="signup.display === 'YES'">
            {{ place.content }}
          </div>
          <div v-if="signup.display === 'NO'" class="pay">
            <div class="tips fc-orange">支付(5元)立即查看报名方式</div>
            <div>
              <van-button
                class="btn"
                :color="COLOR"
                size="mini"
                icon="cart-o"
                @click="payExpense()"
                >立即支付</van-button
              >
            </div>
          </div>
        </van-tab>
        <van-tab
          title="条件要求"
          name="requ"
          v-if="JSON.stringify(requ) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ requ.content }}</div>
        </van-tab>
        <van-tab
          title="工作地点"
          name="place"
          v-if="JSON.stringify(place) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ place.content }}</div>
        </van-tab>
        <!-- <van-tab title="企业介绍" name="major">无 </van-tab> -->
        <van-tab
          title="其他"
          name="other"
          v-if="JSON.stringify(other) !== '{}'"
        >
          <div class="pre-text ta-left content">{{ other.content }}</div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Clue ref="clue" />
    <Call
      ref="call"
      win-show="false"
      entry-show="false"
      clue-model="CERT_CONSULT_ONLINE"
    />
    <Share ref="share" />
    <LogVisit
      ref="logVisit"
      module-type="RCT"
      module-label="RCT_CONSULT_INDEX"
    />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
